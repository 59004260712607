.App {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #F4F3EF;
  background-image: url('https://www.transparenttextures.com/patterns/asfalt-dark.png');
}

.Selected-content {
  z-index: 10;
  position: absolute;
  top: 90vh;
  width: 100%;
  transition: 1.5s;
}

.at-bottom {
  background: rgb(255,255,255);
}

.at-top {
  position: absolute;
  top: 0
}