@import url(/antd/dist/antd.css);
@font-face {
  font-family: "GliscorGothic";
  src: url(/static/media/gligoth.80d37017.eot);
  src: url(/static/media/gligoth.80d37017.eot?#iefix) format("embedded-opentype"),
    url(/static/media/gligoth.615e6199.otf) format("opentype"),
    url(/static/media/gligoth.cf561749.svg) format("svg"),
    url(/static/media/gligoth.db4aad00.ttf) format("truetype"),
    url(/static/media/gligoth.9b12df49.woff) format("woff"),
    url(/static/media/gligoth.ef6bf862.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: GliscorGothic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #F4F3EF;
  background-image: url('https://www.transparenttextures.com/patterns/asfalt-dark.png');
}

.Selected-content {
  z-index: 10;
  position: absolute;
  top: 90vh;
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.at-bottom {
  background: rgb(255,255,255);
}

.at-top {
  position: absolute;
  top: 0
}
.Footer {
    height: 5vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    background-color: #5B7FA7;
    color: rgb(255,255,255);
    width: 100vw;
}
.HeaderLink {
    text-decoration: none;
    display: inline-block;
}

.HeaderLink:hover {
    font-weight: bold;
}

.HeaderLinkText {
    color: #5A7DA5;
}

.header {
    font-size: 1.2em;
}

.home {
    font-size: 1.8em;
    padding: 5vh 5vw;
}
h2 {
    color: #5B7FA7;
    font-weight: 100;
    font-size: 15vh;
    text-align: center;
    margin: 2vh;
}
.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .NavLinks {
    flex-direction: row; }
    .NavLinks .HeaderLink {
      display: inline; } }

.NavLinks {
  width: 80vw; }

.Episodes {
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ContentContainer {
    padding-top: 10vh;
    padding-bottom: 10vh;
    align-content: center;
}
.PlayerEpisode {
    width: 100%;
    text-align: left;
    display: block;
    cursor: pointer;
    background-color: white;
    font-weight: 1.5em;
    padding:0.5em;
}

.PlayerEpisode:hover {
    background-color: #5A7DA5;
    color: white;
}

.selected {
    color: white;
    background-color: #ca8e97;
}
.RssPlayer {
    width: 60vw;
    align-self: center;
}
