@import '~/antd/dist/antd.css';

@font-face {
  font-family: "GliscorGothic";
  src: url("./assets/fonts/gligoth.eot");
  src: url("./assets/fonts/gligoth.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/gligoth.otf") format("opentype"),
    url("./assets/fonts/gligoth.svg") format("svg"),
    url("./assets/fonts/gligoth.ttf") format("truetype"),
    url("./assets/fonts/gligoth.woff") format("woff"),
    url("./assets/fonts/gligoth.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: GliscorGothic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
