.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.NavLinks {
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: row;
    
    .HeaderLink {
      display: inline;
    }
  }
}

.NavLinks {
  width: 80vw;
}
